import React from "react";

const GameVictory = () => {

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
    <div className="card w-25">
            <div className="card-body">
             You Won !
            </div>
          </div>
    </div>
  );
};

export default GameVictory;
