import React from "react";

const GameOver = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="card w-25">
        <div className="card-body">You Losed...</div>
      </div>
    </div>
  );
};

export default GameOver;
